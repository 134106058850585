import { FC } from "react";
import { RouterProvider } from "react-router-dom";
import { Provider as StoreProvider } from "react-redux";
import { router } from "@app/router";
import { asyncStore } from "@shared/store";
import { CssVarsProvider, extendTheme } from "@mui/joy/styles";

import "@shared/ui/bootstrap.scss";

const theme = extendTheme({
  colorSchemes: { light: { palette: { primary: { mainChannel: "#276ef1" } } } },
});

export const App: FC = () => (
  <StoreProvider store={asyncStore.store}>
    <CssVarsProvider theme={theme}>
      <RouterProvider router={router} />
    </CssVarsProvider>
  </StoreProvider>
);
