import { createSelector } from "@reduxjs/toolkit";
import { AuthStatus, TenantEmployeeInfo } from "@shared/models/auth";
import { User, UserRoleTypes } from "@shared/models/user";
import { RootSelector } from "../types";

export const authStatusSelector: RootSelector<AuthStatus> = (state) =>
  state.auth.authStatus;

export const userSelector: RootSelector<User | undefined> = (state) =>
  state.auth.user;

export const rolesSelector: RootSelector<UserRoleTypes[] | undefined> = (
  state
) => state.auth.tenantEmployeeInfo?.employee.employeeRoles;

export const userTenantEmployeeInfosSelector: RootSelector<
  TenantEmployeeInfo | undefined
> = (state) => state.auth.tenantEmployeeInfo;

export const isAdminSelector = createSelector(
  userTenantEmployeeInfosSelector,
  (tenantInfo) =>
    tenantInfo?.employee.employeeRoles.includes(UserRoleTypes.Admin)
);

export const isStoreAdminSelector = createSelector(
  userTenantEmployeeInfosSelector,
  (tenantInfo) =>
    tenantInfo?.employee.employeeRoles.includes(UserRoleTypes.StoreAdmin)
);

export const isRegionalAdminSelector = createSelector(rolesSelector, (roles) =>
  roles?.includes(UserRoleTypes.RegionalAdmin)
);

export const isCenterOfficeAdminSelector = createSelector(
  rolesSelector,
  (roles) => roles?.includes(UserRoleTypes.CenterOfficeAdmin)
);
