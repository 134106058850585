import { forwardRef, useState } from "react";
import { TextField, TextFieldProps } from "../text-field";

import styles from "./index.module.scss";

export const PasswordTextField = forwardRef<HTMLDivElement, TextFieldProps>(
  function PasswordTextField({ endDecorator, ...props }, ref) {
    const [open, setOpen] = useState(false);

    return (
      <TextField
        ref={ref}
        type={open ? "text" : "password"}
        placeholder="Введите пароль"
        endDecorator={
          <>
            <button
              className={styles.decorator_button}
              type="button"
              onClick={() => {
                setOpen((previousOpen) => !previousOpen);
              }}
            >
              {open ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <g id="visibility">
                    <g id="Group 1102">
                      <path
                        id="Vector 185 (Stroke)"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.65023 16.2342L7.65356 16.2273C7.65718 16.2198 7.66341 16.2071 7.67227 16.1895C7.68999 16.1544 7.71819 16.0997 7.75707 16.0284C7.83489 15.8856 7.9551 15.6767 8.11917 15.4236C8.44812 14.9162 8.94868 14.2387 9.63123 13.5628C10.9941 12.2132 13.0537 10.894 15.9287 10.8939C18.8058 10.8939 20.9034 12.215 22.3046 13.5695C23.0057 14.2473 23.5243 14.9264 23.8666 15.435C24.0373 15.6887 24.163 15.8983 24.2446 16.0415C24.2854 16.1131 24.3151 16.168 24.3338 16.2034C24.3431 16.2211 24.3497 16.2339 24.3536 16.2414L24.357 16.2481C24.357 16.2481 24.357 16.2481 24.357 16.2481C24.3571 16.2483 24.3575 16.2492 24.3576 16.2494C24.5532 16.6449 25.0321 16.8074 25.428 16.6125C25.8244 16.4174 25.9876 15.9378 25.7925 15.5414L25.0747 15.8948C25.7925 15.5414 25.7926 15.5418 25.7925 15.5414L25.7907 15.5379L25.7879 15.5323L25.7791 15.5148C25.7718 15.5004 25.7615 15.4807 25.7484 15.4559C25.7222 15.4063 25.6844 15.3365 25.6348 15.2495C25.5357 15.0755 25.3892 14.8318 25.194 14.5417C24.8042 13.9627 24.2158 13.1916 23.4167 12.4191C21.8175 10.8732 19.3421 9.29391 15.9287 9.29395C12.5132 9.29398 10.0713 10.8752 8.50539 12.4259C7.72353 13.2002 7.15284 13.9729 6.77664 14.5532C6.58813 14.844 6.44729 15.0882 6.3522 15.2627C6.30463 15.3499 6.26841 15.4199 6.24333 15.4697C6.23078 15.4946 6.22101 15.5145 6.21399 15.529L6.20551 15.5466L6.2028 15.5523L6.20183 15.5544C6.20166 15.5547 6.20112 15.5559 6.89654 15.8811L6.20111 15.5559C6.01396 15.9561 6.18671 16.4323 6.58694 16.6195C6.98699 16.8065 7.46289 16.634 7.65023 16.2342Z"
                        fill="#707070"
                      />
                      <g id="Vector 187 (Stroke)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M15.9492 21.8898C16.3911 21.8898 16.7492 22.2479 16.7492 22.6898L16.7492 25.7771C16.7492 26.219 16.3911 26.5771 15.9492 26.5771C15.5074 26.5771 15.1492 26.219 15.1492 25.7771L15.1492 22.6898C15.1492 22.2479 15.5074 21.8898 15.9492 21.8898Z"
                          fill="#707070"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M15.9492 21.8898C16.3911 21.8898 16.7492 22.2479 16.7492 22.6898L16.7492 25.7771C16.7492 26.219 16.3911 26.5771 15.9492 26.5771C15.5074 26.5771 15.1492 26.219 15.1492 25.7771L15.1492 22.6898C15.1492 22.2479 15.5074 21.8898 15.9492 21.8898Z"
                          fill="#707070"
                        />
                      </g>
                      <g id="Vector 188 (Stroke)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M19.2041 21.3838C19.5867 21.1629 20.076 21.294 20.2969 21.6766L21.8406 24.3504C22.0615 24.733 21.9304 25.2223 21.5477 25.4432C21.1651 25.6641 20.6758 25.533 20.4549 25.1504L18.9112 22.4766C18.6903 22.094 18.8214 21.6047 19.2041 21.3838Z"
                          fill="#707070"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M19.2041 21.3838C19.5867 21.1629 20.076 21.294 20.2969 21.6766L21.8406 24.3504C22.0615 24.733 21.9304 25.2223 21.5477 25.4432C21.1651 25.6641 20.6758 25.533 20.4549 25.1504L18.9112 22.4766C18.6903 22.094 18.8214 21.6047 19.2041 21.3838Z"
                          fill="#707070"
                        />
                      </g>
                      <g id="Vector 189 (Stroke)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M22.3259 18.9197C22.6384 18.6072 23.1449 18.6072 23.4573 18.9197L25.6404 21.1028C25.9528 21.4152 25.9528 21.9217 25.6404 22.2341C25.328 22.5466 24.8215 22.5466 24.509 22.2341L22.3259 20.051C22.0135 19.7386 22.0135 19.2321 22.3259 18.9197Z"
                          fill="#707070"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M22.3259 18.9197C22.6384 18.6072 23.1449 18.6072 23.4573 18.9197L25.6404 21.1028C25.9528 21.4152 25.9528 21.9217 25.6404 22.2341C25.328 22.5466 24.8215 22.5466 24.509 22.2341L22.3259 20.051C22.0135 19.7386 22.0135 19.2321 22.3259 18.9197Z"
                          fill="#707070"
                        />
                      </g>
                      <g id="Vector 190 (Stroke)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12.6944 21.3838C12.3118 21.1629 11.8225 21.294 11.6016 21.6766L10.0579 24.3504C9.83703 24.733 9.96813 25.2223 10.3508 25.4432C10.7334 25.6641 11.2227 25.533 11.4436 25.1504L12.9873 22.4766C13.2082 22.094 13.0771 21.6047 12.6944 21.3838Z"
                          fill="#707070"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12.6944 21.3838C12.3118 21.1629 11.8225 21.294 11.6016 21.6766L10.0579 24.3504C9.83703 24.733 9.96813 25.2223 10.3508 25.4432C10.7334 25.6641 11.2227 25.533 11.4436 25.1504L12.9873 22.4766C13.2082 22.094 13.0771 21.6047 12.6944 21.3838Z"
                          fill="#707070"
                        />
                      </g>
                      <g id="Vector 191 (Stroke)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9.57256 18.9197C9.26014 18.6072 8.75361 18.6072 8.44119 18.9197L6.25809 21.1028C5.94567 21.4152 5.94567 21.9217 6.25809 22.2341C6.57051 22.5466 7.07704 22.5466 7.38946 22.2341L9.57256 20.051C9.88498 19.7386 9.88498 19.2321 9.57256 18.9197Z"
                          fill="#707070"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9.57256 18.9197C9.26014 18.6072 8.75361 18.6072 8.44119 18.9197L6.25809 21.1028C5.94567 21.4152 5.94567 21.9217 6.25809 22.2341C6.57051 22.5466 7.07704 22.5466 7.38946 22.2341L9.57256 20.051C9.88498 19.7386 9.88498 19.2321 9.57256 18.9197Z"
                          fill="#707070"
                        />
                      </g>
                      <g id="Vector 192 (Stroke)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M15.9492 10.1854C16.3911 10.1854 16.7492 9.82725 16.7492 9.38542L16.7492 6.29805C16.7492 5.85623 16.3911 5.49805 15.9492 5.49805C15.5074 5.49805 15.1492 5.85623 15.1492 6.29805L15.1492 9.38542C15.1492 9.82725 15.5074 10.1854 15.9492 10.1854Z"
                          fill="#707070"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M15.9492 10.1854C16.3911 10.1854 16.7492 9.82725 16.7492 9.38542L16.7492 6.29805C16.7492 5.85623 16.3911 5.49805 15.9492 5.49805C15.5074 5.49805 15.1492 5.85623 15.1492 6.29805L15.1492 9.38542C15.1492 9.82725 15.5074 10.1854 15.9492 10.1854Z"
                          fill="#707070"
                        />
                      </g>
                      <g id="Vector 193 (Stroke)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M19.2041 10.6914C19.5867 10.9123 20.076 10.7812 20.2969 10.3986L21.8406 7.72482C22.0615 7.34218 21.9304 6.85291 21.5477 6.632C21.1651 6.41108 20.6758 6.54218 20.4549 6.92482L18.9112 9.59856C18.6903 9.9812 18.8214 10.4705 19.2041 10.6914Z"
                          fill="#707070"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M19.2041 10.6914C19.5867 10.9123 20.076 10.7812 20.2969 10.3986L21.8406 7.72482C22.0615 7.34218 21.9304 6.85291 21.5477 6.632C21.1651 6.41108 20.6758 6.54218 20.4549 6.92482L18.9112 9.59856C18.6903 9.9812 18.8214 10.4705 19.2041 10.6914Z"
                          fill="#707070"
                        />
                      </g>
                      <g id="Vector 194 (Stroke)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M22.3259 13.1554C22.6384 13.4678 23.1449 13.4678 23.4573 13.1554L25.6404 10.9723C25.9528 10.6599 25.9528 10.1534 25.6404 9.84094C25.328 9.52852 24.8215 9.52852 24.509 9.84094L22.3259 12.024C22.0135 12.3365 22.0135 12.843 22.3259 13.1554Z"
                          fill="#707070"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M22.3259 13.1554C22.6384 13.4678 23.1449 13.4678 23.4573 13.1554L25.6404 10.9723C25.9528 10.6599 25.9528 10.1534 25.6404 9.84094C25.328 9.52852 24.8215 9.52852 24.509 9.84094L22.3259 12.024C22.0135 12.3365 22.0135 12.843 22.3259 13.1554Z"
                          fill="#707070"
                        />
                      </g>
                      <g id="Vector 195 (Stroke)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12.6944 10.6914C12.3118 10.9123 11.8225 10.7812 11.6016 10.3986L10.0579 7.72482C9.83703 7.34219 9.96813 6.85291 10.3508 6.632C10.7334 6.41108 11.2227 6.54219 11.4436 6.92482L12.9873 9.59856C13.2082 9.9812 13.0771 10.4705 12.6944 10.6914Z"
                          fill="#707070"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12.6944 10.6914C12.3118 10.9123 11.8225 10.7812 11.6016 10.3986L10.0579 7.72482C9.83703 7.34219 9.96813 6.85291 10.3508 6.632C10.7334 6.41108 11.2227 6.54219 11.4436 6.92482L12.9873 9.59856C13.2082 9.9812 13.0771 10.4705 12.6944 10.6914Z"
                          fill="#707070"
                        />
                      </g>
                      <g id="Vector 196 (Stroke)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9.57256 13.1554C9.26014 13.4678 8.75361 13.4678 8.44119 13.1554L6.25809 10.9723C5.94567 10.6599 5.94567 10.1534 6.25809 9.84094C6.57051 9.52852 7.07704 9.52852 7.38946 9.84094L9.57256 12.024C9.88498 12.3365 9.88498 12.843 9.57256 13.1554Z"
                          fill="#707070"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9.57256 13.1554C9.26014 13.4678 8.75361 13.4678 8.44119 13.1554L6.25809 10.9723C5.94567 10.6599 5.94567 10.1534 6.25809 9.84094C6.57051 9.52852 7.07704 9.52852 7.38946 9.84094L9.57256 12.024C9.88498 12.3365 9.88498 12.843 9.57256 13.1554Z"
                          fill="#707070"
                        />
                      </g>
                      <path
                        id="Vector 186 (Stroke)"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.65023 15.7653L7.65356 15.7722C7.65718 15.7797 7.66341 15.7924 7.67227 15.81C7.68999 15.8452 7.71819 15.8998 7.75707 15.9711C7.83489 16.1139 7.9551 16.3228 8.11917 16.5759C8.44812 17.0833 8.94868 17.7608 9.63123 18.4367C10.9941 19.7864 13.0537 21.1055 15.9287 21.1056C18.8058 21.1056 20.9034 19.7845 22.3046 18.43C23.0057 17.7523 23.5243 17.0731 23.8666 16.5645C24.0373 16.3108 24.163 16.1012 24.2446 15.958C24.2854 15.8864 24.3151 15.8315 24.3338 15.7961C24.3431 15.7784 24.3497 15.7656 24.3536 15.7581L24.357 15.7514C24.357 15.7514 24.357 15.7514 24.357 15.7514C24.3571 15.7512 24.3575 15.7503 24.3576 15.7501C24.5532 15.3546 25.0321 15.1921 25.428 15.387C25.8244 15.5821 25.9876 16.0617 25.7925 16.4581L25.0747 16.1047C25.7925 16.4581 25.7926 16.4577 25.7925 16.4581L25.7907 16.4616L25.7879 16.4672L25.7791 16.4847C25.7718 16.4991 25.7615 16.5189 25.7484 16.5436C25.7222 16.5932 25.6844 16.663 25.6348 16.75C25.5357 16.924 25.3892 17.1677 25.194 17.4578C24.8042 18.0369 24.2158 18.8079 23.4167 19.5804C21.8175 21.1263 19.3421 22.7056 15.9287 22.7056C12.5132 22.7055 10.0713 21.1243 8.50539 19.5736C7.72353 18.7993 7.15284 18.0266 6.77664 17.4463C6.58813 17.1556 6.44729 16.9113 6.3522 16.7369C6.30463 16.6496 6.26841 16.5796 6.24333 16.5298C6.23078 16.5049 6.22101 16.485 6.21399 16.4705L6.20551 16.4529L6.2028 16.4472L6.20183 16.4451C6.20166 16.4448 6.20112 16.4436 6.89654 16.1184L6.20111 16.4436C6.01396 16.0434 6.18671 15.5672 6.58694 15.3801C6.98699 15.193 7.46289 15.3655 7.65023 15.7653Z"
                        fill="#707070"
                      />
                      <path
                        id="Ellipse 82 (Stroke)"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16 18.8999C17.6016 18.8999 18.9 17.6015 18.9 15.9999C18.9 14.3983 17.6016 13.0999 16 13.0999C14.3984 13.0999 13.1 14.3983 13.1 15.9999C13.1 17.6015 14.3984 18.8999 16 18.8999ZM16 20.4999C18.4853 20.4999 20.5 18.4852 20.5 15.9999C20.5 13.5146 18.4853 11.4999 16 11.4999C13.5147 11.4999 11.5 13.5146 11.5 15.9999C11.5 18.4852 13.5147 20.4999 16 20.4999Z"
                        fill="#707070"
                      />
                    </g>
                  </g>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <g id="visibility">
                    <g id="Group 1121">
                      <path
                        id="Vector 255 (Stroke)"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.5345 15.7654L7.53784 15.7724C7.54146 15.7798 7.54769 15.7925 7.55654 15.8101C7.57426 15.8453 7.60247 15.8999 7.64135 15.9713C7.71917 16.114 7.83938 16.323 8.00345 16.576C8.3324 17.0834 8.83296 17.7609 9.51551 18.4368C10.8784 19.7865 12.9379 21.1057 15.813 21.1057C18.6901 21.1057 20.7877 19.7846 22.1889 18.4301C22.89 17.7524 23.4085 17.0732 23.7508 16.5646C23.9216 16.3109 24.0473 16.1014 24.1289 15.9581C24.1697 15.8865 24.1994 15.8317 24.2181 15.7963C24.2274 15.7786 24.234 15.7658 24.2379 15.7582L24.2413 15.7515C24.2412 15.7516 24.2413 15.7515 24.2413 15.7515C24.2414 15.7513 24.2418 15.7505 24.2419 15.7502C24.4375 15.3548 24.9164 15.1922 25.3123 15.3871C25.7087 15.5823 25.8719 16.0618 25.6768 16.4582L24.959 16.1049C25.6768 16.4582 25.6769 16.4578 25.6768 16.4582L25.675 16.4617L25.6722 16.4673L25.6634 16.4848C25.656 16.4992 25.6458 16.519 25.6327 16.5438C25.6065 16.5934 25.5687 16.6631 25.5191 16.7501C25.42 16.9241 25.2735 17.1678 25.0782 17.4579C24.6885 18.037 24.1 18.808 23.3009 19.5805C21.7017 21.1264 19.2263 22.7057 15.813 22.7057C12.3975 22.7057 9.9556 21.1244 8.38967 19.5737C7.60781 18.7994 7.03711 18.0267 6.66091 17.4464C6.47241 17.1557 6.33157 16.9114 6.23648 16.737C6.1889 16.6497 6.15269 16.5797 6.12761 16.5299C6.11506 16.505 6.10529 16.4851 6.09827 16.4707L6.08978 16.453L6.08708 16.4473L6.08611 16.4453C6.08594 16.4449 6.08539 16.4437 6.78082 16.1185L6.08539 16.4437C5.89824 16.0435 6.07098 15.5673 6.47122 15.3802C6.87126 15.1931 7.34717 15.3656 7.5345 15.7654Z"
                        fill="#707070"
                      />
                      <g id="Vector 256 (Stroke)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M16.0001 21.4099C16.4419 21.4099 16.8001 21.7681 16.8001 22.2099L16.8001 25.2973C16.8001 25.7391 16.4419 26.0973 16.0001 26.0973C15.5582 26.0973 15.2001 25.7391 15.2001 25.2973L15.2001 22.2099C15.2001 21.7681 15.5582 21.4099 16.0001 21.4099Z"
                          fill="#707070"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M16.0001 21.4099C16.4419 21.4099 16.8001 21.7681 16.8001 22.2099L16.8001 25.2973C16.8001 25.7391 16.4419 26.0973 16.0001 26.0973C15.5582 26.0973 15.2001 25.7391 15.2001 25.2973L15.2001 22.2099C15.2001 21.7681 15.5582 21.4099 16.0001 21.4099Z"
                          fill="#707070"
                        />
                      </g>
                      <g id="Vector 257 (Stroke)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M19.2548 20.9036C19.6375 20.6827 20.1267 20.8138 20.3477 21.1964L21.8913 23.8702C22.1123 24.2528 21.9812 24.7421 21.5985 24.963C21.2159 25.1839 20.7266 25.0528 20.5057 24.6701L18.962 21.9964C18.7411 21.6138 18.8722 21.1245 19.2548 20.9036Z"
                          fill="#707070"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M19.2548 20.9036C19.6375 20.6827 20.1267 20.8138 20.3477 21.1964L21.8913 23.8702C22.1123 24.2528 21.9812 24.7421 21.5985 24.963C21.2159 25.1839 20.7266 25.0528 20.5057 24.6701L18.962 21.9964C18.7411 21.6138 18.8722 21.1245 19.2548 20.9036Z"
                          fill="#707070"
                        />
                      </g>
                      <g id="Vector 258 (Stroke)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M22.3767 18.4399C22.6891 18.1275 23.1957 18.1275 23.5081 18.4399L25.6912 20.623C26.0036 20.9355 26.0036 21.442 25.6912 21.7544C25.3788 22.0668 24.8722 22.0668 24.5598 21.7544L22.3767 19.5713C22.0643 19.2589 22.0643 18.7523 22.3767 18.4399Z"
                          fill="#707070"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M22.3767 18.4399C22.6891 18.1275 23.1957 18.1275 23.5081 18.4399L25.6912 20.623C26.0036 20.9355 26.0036 21.442 25.6912 21.7544C25.3788 22.0668 24.8722 22.0668 24.5598 21.7544L22.3767 19.5713C22.0643 19.2589 22.0643 18.7523 22.3767 18.4399Z"
                          fill="#707070"
                        />
                      </g>
                      <g id="Vector 259 (Stroke)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12.7453 20.9036C12.3627 20.6827 11.8734 20.8138 11.6525 21.1964L10.1088 23.8702C9.88787 24.2528 10.019 24.7421 10.4016 24.963C10.7842 25.1839 11.2735 25.0528 11.4944 24.6701L13.0381 21.9964C13.259 21.6138 13.1279 21.1245 12.7453 20.9036Z"
                          fill="#707070"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12.7453 20.9036C12.3627 20.6827 11.8734 20.8138 11.6525 21.1964L10.1088 23.8702C9.88787 24.2528 10.019 24.7421 10.4016 24.963C10.7842 25.1839 11.2735 25.0528 11.4944 24.6701L13.0381 21.9964C13.259 21.6138 13.1279 21.1245 12.7453 20.9036Z"
                          fill="#707070"
                        />
                      </g>
                      <g id="Vector 260 (Stroke)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9.62328 18.4399C9.31086 18.1275 8.80433 18.1275 8.49191 18.4399L6.30881 20.623C5.99639 20.9355 5.99639 21.442 6.30881 21.7544C6.62123 22.0668 7.12776 22.0668 7.44018 21.7544L9.62328 19.5713C9.9357 19.2589 9.9357 18.7523 9.62328 18.4399Z"
                          fill="#707070"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9.62328 18.4399C9.31086 18.1275 8.80433 18.1275 8.49191 18.4399L6.30881 20.623C5.99639 20.9355 5.99639 21.442 6.30881 21.7544C6.62123 22.0668 7.12776 22.0668 7.44018 21.7544L9.62328 19.5713C9.9357 19.2589 9.9357 18.7523 9.62328 18.4399Z"
                          fill="#707070"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              )}
            </button>
            {endDecorator}
          </>
        }
        {...props}
      />
    );
  }
);
