import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { authDomain, authInitialState } from "./constants";
import {
  authRefresh,
  authLogin,
  authLogout,
  authPasswordReset,
  authPasswordChange,
} from "./async-thunks";

export const authSlice = createSlice({
  name: authDomain,
  initialState: authInitialState,
  reducers: {},
  extraReducers: ({ addCase, addMatcher }) => {
    addCase(authPasswordReset.fulfilled, (draft, action) => {
      draft.recovery.phone = action.meta.arg.phone;
      draft.recovery.country = action.meta.arg.country;
      draft.recovery.token = action.payload.token;
      draft.recovery.expired = action.payload.expired;
    });

    addCase(authPasswordChange.fulfilled, (draft) => {
      draft.recovery = {};
    });

    addMatcher(
      isAnyOf(authLogin.fulfilled, authRefresh.fulfilled),
      (draft, { payload }) => {
        draft.authStatus = "authorized";
        draft.user = payload.user;
        draft.tenantEmployeeInfo = payload.tenantEmployeeInfos?.[0];
      }
    );

    addMatcher(
      isAnyOf(authLogin.rejected, authRefresh.rejected, authLogout.fulfilled),
      (draft) => {
        draft.authStatus = "not-authorized";
      }
    );
  },
});
