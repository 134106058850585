import {
  AddZoneApi,
  DeleteZoneApi,
  EditZoneApi,
  UploadZoneIconApi,
  ZoneAllApi,
} from "@shared/models/zones";
import { requestInstance } from "@shared/request-instance";

export const zoneAllApi: ZoneAllApi = async () => {
  const response = await requestInstance.get("/zone/all");

  return response.data;
};

export const uploadZoneIconApi: UploadZoneIconApi = async (files) => {
  const formData = new FormData();
  const file = files[0];
  if (file != null) {
    formData.append("file", file);
  }

  const response = await requestInstance.post("/zone/icon/upload", formData);

  return response.data;
};

export const editZoneApi: EditZoneApi = async (params) => {
  const response = await requestInstance.put(`/zone/${params.id}`, params);

  return response.data;
};

export const deleteZoneApi: DeleteZoneApi = async (zoneId: number) => {
  const response = await requestInstance.delete(`/zone/${zoneId}`);

  return response.data;
};

export const addZoneApi: AddZoneApi = async (zone) => {
  const response = await requestInstance.post("/zone", zone);

  return response.data;
};
