import { isStoreFilter } from "@shared/utils/is-store-filter";
import { storesSlice } from ".";
import { authRefresh } from "../auth/async-thunks";
import { startAppListening } from "../listener-middleware";
import { storeFiltersKey } from "./constants";

startAppListening({
  actionCreator: authRefresh.pending,
  effect: (_, { dispatch }) => {
    const storeFilters = JSON.parse(
      localStorage.getItem(storeFiltersKey) ?? "null"
    );

    if (isStoreFilter(storeFilters)) {
      dispatch(storesSlice.actions.setStoreFilter(storeFilters));
    }
  },
});
