import {
  channelThreadAddApi,
  channelThreadCommentsAddApi,
  channelThreadCommentsDeleteApi,
  channelThreadCommentsListApi,
  channelThreadDeleteApi,
  channelThreadListApi,
  channelThreadRatingAddApi,
  channelThreadViewApi,
} from "@shared/api/channel";
import { downloadMediaApi, mediaUploadApi } from "@shared/api/media";
import { createAppAsyncThunk } from "../create-app-async-thunk";
import { channelDomain } from "./constants";

export const channelThreadList = createAppAsyncThunk(
  `${channelDomain}/channelThreadList`,
  channelThreadListApi
);

export const channelThreadCommentsList = createAppAsyncThunk(
  `${channelDomain}/channelThreadCommentsList`,
  channelThreadCommentsListApi
);

export const channelThreadAdd = createAppAsyncThunk(
  `${channelDomain}/channelThreadAdd`,
  channelThreadAddApi
);

export const channelThreadDelete = createAppAsyncThunk(
  `${channelDomain}/channelThreadDelete`,
  channelThreadDeleteApi
);

export const channelThreadCommentsAdd = createAppAsyncThunk(
  `${channelDomain}/channelThreadCommentsAdd`,
  channelThreadCommentsAddApi
);

export const channelThreadCommentsDelete = createAppAsyncThunk(
  `${channelDomain}/channelThreadCommentsDelete`,
  channelThreadCommentsDeleteApi
);

export const channelThreadView = createAppAsyncThunk(
  `${channelDomain}/channelThreadView`,
  channelThreadViewApi
);

export const uploadMedia = createAppAsyncThunk(
  `${channelDomain}/uploadMedia`,
  mediaUploadApi
);

export const downloadMedia = createAppAsyncThunk(
  `${channelDomain}/downloadMedia`,
  downloadMediaApi
);

export const channelThreadRatingAdd = createAppAsyncThunk(
  `${channelDomain}/channelThreadRatingAdd`,
  channelThreadRatingAddApi
);
