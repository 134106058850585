import { AuthStatus, TenantEmployeeInfo } from "@shared/models/auth";
import { User } from "@shared/models/user";

export enum CountryTypes {
  Ru = "Ru",
  Kz = "Kz",
  Bl = "Bl",
  Am = "Am",
}

export const countryTypes = [
  CountryTypes.Ru,
  CountryTypes.Kz,
  CountryTypes.Bl,
  CountryTypes.Am,
];

export const CountryNames: Record<CountryTypes, string> = {
  [CountryTypes.Ru]: "Россия",
  [CountryTypes.Kz]: "Казахстан",
  [CountryTypes.Bl]: "Беларусь",
  [CountryTypes.Am]: "Армения",
};

export const CountryCodes: Record<CountryTypes, string> = {
  [CountryTypes.Ru]: "+7",
  [CountryTypes.Kz]: "+7",
  [CountryTypes.Bl]: "+375",
  [CountryTypes.Am]: "+374",
};

export const CountryMasks: Record<CountryTypes, string> = {
  // eslint-disable-next-line no-nonoctal-decimal-escape, prettier/prettier
  [CountryTypes.Ru]: `(\\999)-999-99-99`,
  [CountryTypes.Kz]: "(799)-999-99-99",
  [CountryTypes.Bl]: "(99)-999-99-99",
  [CountryTypes.Am]: "(99)-999999",
};

export interface AuthState {
  authStatus: AuthStatus;
  user?: User;
  tenantEmployeeInfo?: TenantEmployeeInfo;
  recovery: {
    phone?: string;
    token?: string;
    expired?: string;
    country?: CountryTypes;
  };
}
