import { FC } from "react";
import { FieldError, SubmitHandler, useForm } from "react-hook-form";
import { Button } from "@mui/joy";
import { AuthPasswordFormData } from "@shared/models/auth";
import { PasswordTextField } from "@shared/ui/password-text-field";
import styles from "./index.module.scss";

const passwordResolver = (value = ""): FieldError | undefined => {
  const requiredError: FieldError | undefined =
    value.length === 0
      ? { type: "required", message: "Обязательное поле" }
      : undefined;

  if (requiredError) {
    return requiredError;
  }

  if (/^(?=.*[A-Z])(?=.*\d)[^>]{10,}$/.test(value)) {
    return undefined;
  }

  return { type: "validate", message: "Ненадежный пароль" };
};

const confirmPasswordResolver = (
  password: string,
  confirmPassword: string
): FieldError | undefined => {
  const requiredError: FieldError | undefined =
    confirmPassword.length === 0
      ? { type: "required", message: "Обязательное поле" }
      : undefined;

  if (requiredError) {
    return requiredError;
  }

  if (password !== confirmPassword) {
    return {
      type: "value",
      message: "Пароли не совпадают",
    };
  }

  return undefined;
};

interface PasswordFormProps {
  error?: string;
  onSubmit: SubmitHandler<AuthPasswordFormData>;
}

export const PasswordForm: FC<PasswordFormProps> = ({ error, onSubmit }) => {
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<AuthPasswordFormData>({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: { password: "", confirmPassword: "" },
    resolver: (values) => {
      const passwordError = passwordResolver(values.password);

      const confirmPasswordError = confirmPasswordResolver(
        values.password,
        values.confirmPassword
      );

      if (passwordError || confirmPasswordError) {
        return {
          values: {},
          errors: {
            password: passwordError,
            confirmPassword: confirmPasswordError,
          },
        };
      }

      return { values, errors: {} };
    },
  });

  const formError =
    errors.password?.message || errors.confirmPassword?.message || error;

  return (
    <form className={styles.auth_form} onSubmit={handleSubmit(onSubmit)}>
      <PasswordTextField
        fullWidth
        required
        placeholder="Новый пароль"
        error={Boolean(errors.password)}
        {...register("password")}
      />
      <PasswordTextField
        fullWidth
        required
        placeholder="Подтверждение пароля"
        error={Boolean(errors.confirmPassword)}
        {...register("confirmPassword", {
          required: {
            value: true,
            message: "Обязательное поле",
          },
        })}
      />
      <div className={styles.auth_form__info}>
        {formError && (
          <div className={styles.auth_form__info__alert}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <g id="danger">
                <g id="danger_2">
                  <path
                    d="M12 6C12.5523 6 13 6.44772 13 7V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V7C11 6.44772 11.4477 6 12 6Z"
                    fill="#D44333"
                  />
                  <path
                    d="M12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16Z"
                    fill="#D44333"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12Z"
                    fill="#D44333"
                  />
                </g>
              </g>
            </svg>
            <span className={styles.auth_form__info__alert__message}>
              {formError}
            </span>
          </div>
        )}
      </div>
      <Button
        type="submit"
        disabled={!isValid}
        className={styles.auth_form__button}
      >
        Подтвердить
      </Button>
    </form>
  );
};
