import { FC, useEffect } from "react";
import { AuthContainer } from "@shared/containers/auth";
import { useAppDispatch, useAppSelector } from "@shared/store/hooks";
import { authRefresh } from "@shared/store/auth/async-thunks";
import PageContent from "@shared/components/page-content";
import { authStatusSelector } from "@shared/store/auth/selectors";
import { dynamic } from "@shared/lib/dynamic";

const ProtectedApp = dynamic(() => import("./pages/protected-app"));

export const AppLayout: FC = () => {
  const dispatch = useAppDispatch();

  const authStatus = useAppSelector(authStatusSelector);

  useEffect(() => {
    dispatch(authRefresh());
  }, [dispatch]);

  if (authStatus === "none") {
    return <PageContent />;
  }

  if (authStatus === "authorized") {
    return <ProtectedApp />;
  }

  return <AuthContainer />;
};
