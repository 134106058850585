import {
  Media,
  MediasDownloadApi,
  MediasUploadApi,
  MediaType,
  MediaUploadApi,
} from "@shared/models/media";
import { requestInstance } from "@shared/request-instance";
import { downloadBlobFile } from "@shared/utils/download-blob-file";
import { getMediaTypeByFileType } from "@shared/utils/get-media-type-by-file-type";
import { getVideoDurationInSeconds } from "@shared/utils/get-video-duration";
import Compressor from "compressorjs";

const compressImage = (file: File) =>
  new Promise<Blob | File>((resolve, reject) => {
    // eslint-disable-next-line no-new
    new Compressor(file, {
      quality: 0.7,
      success(result) {
        resolve(result);
      },
      error(error) {
        reject(error);
      },
    });
  });

export const mediaUploadApi: MediaUploadApi = async (file) => {
  const mediaType = getMediaTypeByFileType(file.type);

  if (mediaType === MediaType.Video) {
    const duration = await getVideoDurationInSeconds(file);

    if (duration < 15) {
      throw new Error("Продолжительность видео не может быть меньше 15 секунд");
    }

    if (duration > 5 * 60) {
      throw new Error("Продолжительность видео не может быть больше 5 минут");
    }
  }

  const compressedFile =
    mediaType === MediaType.Image ? await compressImage(file) : file;

  const formData = new FormData();
  const fileNameBase64 = window.btoa(unescape(encodeURIComponent(file.name)));

  formData.append("file", compressedFile);

  const response = await requestInstance.post<Media>(
    "/media/upload/v2",
    formData,
    {
      headers: {
        create: new Date(file.lastModified).toISOString(),
        mediaType: mediaType.toString(),
        FileNameBase64: fileNameBase64,
      },
    }
  );

  return response.data;
};

export const mediasUploadApi: MediasUploadApi = async (files) => {
  const media = await Promise.all(Array.from(files).map(mediaUploadApi));

  return media;
};

export const downloadMediaApi: MediasDownloadApi = async ({
  url,
  fileName,
}) => {
  const response = await requestInstance.get(url, { responseType: "blob" });

  downloadBlobFile({ blob: response.data, fileName });
};
