import {
  addZoneApi,
  deleteZoneApi,
  editZoneApi,
  zoneAllApi,
} from "@shared/api/zones";
import { createAppAsyncThunk } from "../create-app-async-thunk";
import { zonesDomain } from "./constants";

export const zoneAll = createAppAsyncThunk(
  `${zonesDomain}/zoneAll`,
  zoneAllApi
);

export const editZone = createAppAsyncThunk(
  `${zonesDomain}/editZone`,
  editZoneApi
);

export const deleteZone = createAppAsyncThunk(
  `${zonesDomain}/deleteZone`,
  deleteZoneApi
);

export const addZone = createAppAsyncThunk(
  `${zonesDomain}/addZone`,
  addZoneApi
);
