interface DownloadBlobFileParams {
  blob: Blob;
  fileName?: string;
}

export const downloadBlobFile = async ({
  blob,
  fileName = "file",
}: DownloadBlobFileParams) => {
  const fileUrl = URL.createObjectURL(blob);

  const anchor = document.createElement("a");
  anchor.href = fileUrl;
  anchor.download = fileName;

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};
