import { StoreZone, StoreZoneStatus } from "@shared/models/stores";

export const getAccumulatedZoneStatus = (
  zoneInfos: StoreZone[]
): StoreZoneStatus => {
  if (zoneInfos.some(({ status }) => status === 2)) {
    return 2;
  }

  if (zoneInfos.some(({ status }) => status === 1)) {
    return 1;
  }

  return 0;
};
