import { router } from "@app/router";
import { zonesSlice } from ".";
import { startAppListening } from "../listener-middleware";
import { loadingSlice } from "../loading";
import { addZone, editZone } from "./async-thunks";

startAppListening({
  actionCreator: addZone.fulfilled,
  effect: () => {
    router.navigate("/zones");
  },
});

startAppListening({
  actionCreator: zonesSlice.actions.managedZone,
  effect: (_, { dispatch }) => {
    dispatch(loadingSlice.actions.reset(editZone.typePrefix));
    dispatch(loadingSlice.actions.reset(addZone.typePrefix));
  },
});
