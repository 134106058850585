import { Button as JoiButton, ButtonTypeMap, ExtendButton } from "@mui/joy";

// @ts-expect-error -- Проблема с типами
export const Button: ExtendButton<
  ButtonTypeMap<{ active?: boolean }, "button">
  // @ts-expect-error -- Проблема с типами
> = ({ active, ...props }) => (
  <JoiButton
    size="sm"
    variant="outlined"
    sx={{
      borderRadius: 12,
      minHeight: "auto",

      padding: "0.25rem 0.5rem !important",
      lineHeight: "24px !important",
      border: "1px solid #e2e2e2",

      "&:active, &:focus": {
        borderColor: "#e2e2e2",
        backgroundColor: "transparent",
      },

      ...(active && {
        borderColor: "#333",
        backgroundColor: "#e0e0e0",

        "&:hover, &:active, &:focus": {
          borderColor: "#333",
          backgroundColor: "#e0e0e0",
        },
      }),
    }}
    {...props}
  />
);
