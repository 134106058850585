import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
  PayloadAction,
} from "@reduxjs/toolkit";
import { loadingDomain, loadingInitialState } from "./constants";
import { loadingEntityAdapter } from "./entity-adapters";
import { IFulfilledAction, IPendingAction, IRejectedAction } from "./types";

export const getTypePrefixByAction = (
  action: IPendingAction | IFulfilledAction | IRejectedAction
): string =>
  action.type.replace(new RegExp(`/${action.meta.requestStatus}$`), "");

export const loadingSlice = createSlice({
  name: loadingDomain,
  initialState: loadingInitialState,
  reducers: {
    reset: (draft, action: PayloadAction<string>) => {
      loadingEntityAdapter.removeOne(draft, action.payload);
    },
  },
  extraReducers: ({ addMatcher }) => {
    addMatcher(isPending, (draft, action) => {
      const typePrefix = getTypePrefixByAction(action);

      loadingEntityAdapter.upsertOne(draft, {
        typePrefix,
        loadingStatus: "pending",
        error: undefined,
      });
    });

    addMatcher(isFulfilled, (draft, action) => {
      const typePrefix = getTypePrefixByAction(action);

      loadingEntityAdapter.upsertOne(draft, {
        typePrefix,
        loadingStatus: "fulfilled",
        error: undefined,
      });
    });

    addMatcher(isRejected, (draft, action) => {
      const typePrefix = getTypePrefixByAction(action);

      loadingEntityAdapter.upsertOne(draft, {
        typePrefix,
        loadingStatus: "rejected",
        error: action.error.message,
      });
    });
  },
});
