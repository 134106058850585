import {
  AsyncThunk,
  AsyncThunkPayloadCreator,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { AsyncThunkConfig } from "./types";

export const createAppAsyncThunk = <Returned, ThunkArgument = void>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<
    Returned,
    ThunkArgument,
    AsyncThunkConfig
  >
): AsyncThunk<Returned, ThunkArgument, AsyncThunkConfig> =>
  createAsyncThunk<Returned, ThunkArgument, AsyncThunkConfig>(
    typePrefix,
    payloadCreator
  );
