import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import FormHelperText from "@mui/joy/FormHelperText";
import { Input, InputProps } from "@mui/joy";
import { forwardRef, ReactNode, ForwardRefRenderFunction } from "react";
import { SxProps } from "@mui/joy/styles/types";

export interface TextFieldProps extends InputProps {
  label?: string;
  helperText?: ReactNode;
  inputSx?: SxProps;
}

export const InnerTextField: ForwardRefRenderFunction<
  HTMLDivElement,
  TextFieldProps
> = (
  { label, helperText, required, sx, error, className, inputSx, ...props },
  ref
) => (
  <FormControl sx={sx} className={className} error={error}>
    {label && (
      <FormLabel required={required} sx={{ color: error ? "red" : "initial" }}>
        {label}
      </FormLabel>
    )}
    <Input
      ref={ref}
      sx={{
        backgroundColor: "#EFEFEF",
        borderColor: "#EFEFEF",
        padding: "0.5rem",
        ...inputSx,
      }}
      {...props}
      error={error}
    />
    {helperText && (
      <FormHelperText sx={{ color: "danger.500" }}>{helperText}</FormHelperText>
    )}
  </FormControl>
);

export const TextField = forwardRef(InnerTextField);
