import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MediaType, MediaWithLoadingStatus } from "@shared/models/media";
import { getMediaTypeByFileType } from "@shared/utils/get-media-type-by-file-type";
import {
  channelThreadAdd,
  channelThreadCommentsAdd,
  channelThreadCommentsDelete,
  channelThreadCommentsList,
  channelThreadDelete,
  channelThreadList,
  channelThreadRatingAdd,
  channelThreadView,
  uploadMedia,
} from "./async-thunks";
import { channelDomain, channelInitialState } from "./constants";
import { mediaEntityAdapter } from "./entity-adapters";

export const channelSlice = createSlice({
  name: channelDomain,
  initialState: channelInitialState,
  reducers: {
    resetThreads: (draft) => {
      draft.threads = [];
    },
    resetComments: (draft) => {
      draft.comments = [];
    },
    deleteMedia: (draft, action: PayloadAction<MediaWithLoadingStatus>) => {
      mediaEntityAdapter.removeOne(draft.media, action.payload.id);
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(channelThreadList.pending, (draft, action) => {
      draft.channelId = action.meta.arg;
      mediaEntityAdapter.removeAll(draft.media);
    });

    addCase(channelThreadList.fulfilled, (draft, action) => {
      draft.threads = action.payload;
    });

    addCase(channelThreadCommentsList.pending, (draft, action) => {
      draft.threadId = action.meta.arg;
      mediaEntityAdapter.removeAll(draft.media);
    });

    addCase(channelThreadCommentsList.fulfilled, (draft, action) => {
      draft.comments = action.payload;
    });

    addCase(channelThreadAdd.fulfilled, (draft, action) => {
      draft.threads.push(action.payload);
      mediaEntityAdapter.removeAll(draft.media);
    });

    addCase(channelThreadDelete.pending, (draft, action) => {
      draft.threads = draft.threads.filter(
        (comment) => comment.threadId !== action.meta.arg.threadId
      );
    });

    addCase(channelThreadCommentsAdd.fulfilled, (draft, action) => {
      draft.comments.push(action.payload);
      mediaEntityAdapter.removeAll(draft.media);
    });

    addCase(channelThreadCommentsDelete.pending, (draft, action) => {
      draft.comments = draft.comments.filter(
        (comment) => comment.messageId !== action.meta.arg.messageId
      );
    });

    addCase(channelThreadRatingAdd.fulfilled, (draft, { payload }) => {
      const updatedThreads = draft.threads.map((thread) => {
        if (thread.threadId === payload.threadId) {
          thread.ratings.push(payload);

          return thread;
        }

        return thread;
      });

      draft.threads = updatedThreads;
    });

    addCase(channelThreadView.pending, (draft, action) => {
      if (action.meta.arg.messageId) {
        draft.threads = draft.threads.map((thread) =>
          thread.threadId === action.meta.arg.threadId
            ? {
                ...thread,
                newMessagesCount: Math.max(0, thread.newMessagesCount - 1),
                viewed: true,
              }
            : thread
        );

        draft.comments = draft.comments.map((comment) =>
          comment.messageId === action.meta.arg.messageId
            ? { ...comment, viewed: true }
            : comment
        );
      } else {
        draft.threads = draft.threads.map((thread) =>
          thread.threadId === action.meta.arg.threadId
            ? { ...thread, viewed: true }
            : thread
        );
      }
    });

    addCase(uploadMedia.pending, (draft, action) => {
      const mediaType = getMediaTypeByFileType(action.meta.arg.type);

      mediaEntityAdapter.addOne(draft.media, {
        id: action.meta.requestId,
        loadingStatus: action.meta.requestStatus,
        media:
          mediaType === MediaType.Image
            ? {
                key: action.meta.requestId,
                link: "",
                type: mediaType,
              }
            : {
                key: action.meta.requestId,
                link: "",
                type: mediaType,
                previewLink: "/video-placeholder.png",
              },
      });
    });

    addCase(uploadMedia.fulfilled, (draft, action) => {
      mediaEntityAdapter.updateOne(draft.media, {
        id: action.meta.requestId,
        changes: {
          loadingStatus: action.meta.requestStatus,
          media:
            action.payload.type === MediaType.Image ||
            action.payload.type === MediaType.File
              ? action.payload
              : {
                  ...action.payload,
                  previewLink:
                    action.payload.previewLink ?? "/video-placeholder.png",
                },
        },
      });
    });

    addCase(uploadMedia.rejected, (draft, action) => {
      const media = mediaEntityAdapter
        .getSelectors()
        .selectById(draft.media, action.meta.requestId);

      if (media) {
        mediaEntityAdapter.updateOne(draft.media, {
          id: action.meta.requestId,
          changes: {
            loadingStatus: action.meta.requestStatus,
            media:
              media.media.type === MediaType.Image ||
              media.media.type === MediaType.File
                ? media.media
                : {
                    ...media.media,
                    previewLink: "/video-error.png",
                  },
            title: action.error.message,
          },
        });
      }
    });
  },
});
