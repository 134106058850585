import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StoreFilters } from "@shared/models/stores";
import { channelThreadView } from "../channel/async-thunks";
import {
  storeList,
  storeListWithZones,
  storeZoneList,
  storeZoneSettings,
  updateStoreZonesSettings,
} from "./async-thunks";
import { storesDomain, storesInitialState } from "./constants";

export const storesSlice = createSlice({
  name: storesDomain,
  initialState: storesInitialState,
  reducers: {
    toggleWithoutPhoto: (draft) => {
      draft.withoutPhoto = !draft.withoutPhoto;
    },
    setStoreFilter: (draft, action: PayloadAction<StoreFilters>) => {
      draft.storeFilters = action.payload;

      delete draft.search;
      delete draft.withoutPhoto;
    },
    setSearch: (draft, action: PayloadAction<string>) => {
      draft.search = action.payload;
    },
    resetStore: (draft) => {
      delete draft.store;
    },
    openEditStoreModal: (draft) => {
      draft.isZonesEditModalOpen = true;
    },
    closeEditStoreModal: (draft) => {
      draft.isZonesEditModalOpen = false;
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(storeList.fulfilled, (draft, action) => {
      draft.stores = action.payload;
    });

    addCase(storeListWithZones.fulfilled, (draft, action) => {
      draft.storesWithZones = action.payload;
    });

    addCase(storeZoneList.fulfilled, (draft, action) => {
      draft.store = action.payload;
    });

    addCase(storeZoneSettings.fulfilled, (draft, action) => {
      draft.storeZonesSettings = action.payload;
    });

    addCase(updateStoreZonesSettings.fulfilled, (draft, action) => {
      draft.isZonesEditModalOpen = false;
      draft.store = action.payload;
    });

    addCase(channelThreadView.pending, (draft, action) => {
      if (draft.store && draft.store.storeZones) {
        const { channelId } = action.meta.arg;

        draft.store.storeZones = draft.store.storeZones.map((storeZone) =>
          storeZone.channel.channelId === channelId
            ? {
                ...storeZone,
                channel: {
                  ...storeZone.channel,
                  newMessagesCount: Math.max(
                    0,
                    storeZone.channel.newMessagesCount - 1
                  ),
                },
              }
            : storeZone
        );
      }
    });
  },
});
