import { FC, useState } from "react";
import { TextField } from "@shared/ui/text-field";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { AuthLoginForm } from "@shared/models/auth";
import { Box, Button, Option, Select } from "@mui/joy";
import ReactInputMask from "react-input-mask";
import {
  CountryCodes,
  CountryMasks,
  CountryNames,
  CountryTypes,
  countryTypes,
} from "@shared/store/auth/types";
import { PasswordTextField } from "@shared/ui/password-text-field";
import styles from "./index.module.scss";

interface AuthFormProps {
  error?: boolean;
  onSubmit: SubmitHandler<AuthLoginForm>;
  onClickRecovery: () => void;
}

export const AuthForm: FC<AuthFormProps> = ({
  error,
  onSubmit,
  onClickRecovery: handleClickRecovery,
}) => {
  const [openListBox, setOpenListBox] = useState(false);

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm<AuthLoginForm>({
    defaultValues: { phone: "", password: "", country: CountryTypes.Ru },
    resolver: (values) => {
      const { phone, password } = values;

      const passwordError = password === "" ? { type: "validate" } : undefined;
      const phoneError =
        phone === "" || phone.includes("*") ? { type: "validate" } : undefined;

      if (passwordError || phoneError) {
        return {
          values,
          errors: {
            password: passwordError,
            phone: phoneError,
          },
        };
      }

      return { values, errors: {} };
    },
  });

  const country = watch("country");
  const password = watch("password");

  const passwordError = Boolean(errors.password);

  return (
    <form className={styles.auth_form} onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ display: "grid", gap: 1 }}>
        <Box sx={{ display: "flex", height: 50, gap: 1 }}>
          <Select
            slotProps={{
              listbox: { placement: "bottom-start" },
            }}
            listboxOpen={openListBox}
            // eslint-disable-next-line react/jsx-handler-names
            onListboxOpenChange={setOpenListBox}
            value={country}
            onChange={(_, value) => {
              if (value) {
                setValue("phone", "");
                setValue("country", value);
              }
            }}
            renderValue={(option) =>
              option && <strong>{CountryCodes[option.value]}</strong>
            }
            indicator={
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ transform: openListBox ? "rotate(90deg)" : undefined }}
              >
                <path
                  d="M9 18.5L15 12.5L9 6.5"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            }
          >
            {countryTypes.map((country) => (
              <Option key={country} value={country}>
                <Box component="strong" width={60}>
                  {CountryCodes[country]}
                </Box>
                <span>{CountryNames[country]}</span>
              </Option>
            ))}
          </Select>
          <Controller
            control={control}
            name="phone"
            rules={{ required: { message: "", value: true } }}
            render={({ field: { value, onChange: handleChange } }) => (
              <ReactInputMask
                mask={CountryMasks[country]}
                maskChar="*"
                alwaysShowMask
                value={value}
                onChange={handleChange}
              >
                {
                  // @ts-expect-error -- Ошибка типов
                  (inputProps) => (
                    <TextField
                      sx={{ flexGrow: 1, height: "inherit" }}
                      inputSx={{ height: "inherit" }}
                      {...inputProps}
                      type="tel"
                      error={Boolean(errors.phone)}
                    />
                  )
                }
              </ReactInputMask>
            )}
          />
        </Box>
        <PasswordTextField
          startDecorator={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <g id="Ð½Ðµ Ð²Ð¸Ð´Ð½Ð¾/Ð²Ð¸Ð´Ð½Ð¾/Ð·Ð°Ð±Ð»Ð¾ÐºÐ¸ÑÐ¾Ð²Ð°Ð½Ð¾">
                <g id="Group 1096">
                  <path
                    id="Rectangle 631 (Stroke)"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.7755 24L21.7755 14.3789C21.7755 14.158 21.5964 13.9789 21.3755 13.9789L9.93119 13.9789C9.71027 13.9789 9.53119 14.158 9.53119 14.3789L9.53119 24C9.53119 24.2209 9.71027 24.4 9.93119 24.4L21.3755 24.4C21.5964 24.4 21.7755 24.2209 21.7755 24ZM23.3755 14.3789C23.3755 13.2743 22.4801 12.3789 21.3755 12.3789L9.93119 12.3789C8.82662 12.3789 7.93119 13.2743 7.93119 14.3789L7.93119 24C7.93119 25.1045 8.82662 26 9.93119 26L21.3755 26C22.4801 26 23.3755 25.1045 23.3755 24L23.3755 14.3789Z"
                    fill={passwordError ? "#D44333" : "#2F80ED"}
                  />
                  <path
                    id="Rectangle 632 (Stroke)"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.9371 12.5738L19.9371 10C19.9371 8.67452 18.8626 7.6 17.5371 7.6L13.7688 7.6C12.4433 7.6 11.3688 8.67452 11.3688 10L11.3688 12.5738L19.9371 12.5738ZM21.5371 10C21.5371 7.79086 19.7462 6 17.5371 6L13.7688 6C11.5596 6 9.76879 7.79086 9.76879 10L9.76879 14.1738L21.5371 14.1738L21.5371 10Z"
                    fill={passwordError ? "#D44333" : "#2F80ED"}
                  />
                  <path
                    id="Ellipse 102"
                    d="M17.1528 18.0862C17.1528 18.9146 16.4813 19.5862 15.6528 19.5862C14.8244 19.5862 14.1528 18.9146 14.1528 18.0862C14.1528 17.2578 14.8244 16.5862 15.6528 16.5862C16.4813 16.5862 17.1528 17.2578 17.1528 18.0862Z"
                    fill={passwordError ? "#D44333" : "#2F80ED"}
                  />
                  <path
                    id="Vector 245 (Stroke)"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.6533 17.8369C16.0951 17.8369 16.4533 18.1951 16.4533 18.6369L16.4533 21.7925C16.4533 22.2343 16.0951 22.5925 15.6533 22.5925C15.2114 22.5925 14.8533 22.2343 14.8533 21.7925L14.8533 18.6369C14.8533 18.1951 15.2114 17.8369 15.6533 17.8369Z"
                    fill={passwordError ? "#D44333" : "#2F80ED"}
                  />
                </g>
              </g>
            </svg>
          }
          endDecorator={
            password.length > 0 && (
              <button
                type="button"
                className={styles.auth_form__decorator_button}
                onClick={() => {
                  setValue("password", "");
                }}
                style={{ marginLeft: "0.5rem" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 35 35"
                  fill="none"
                >
                  <g id="Ð·Ð°ÐºÑÑÑÑ">
                    <circle
                      id="Ellipse 2.1"
                      cx="17.5"
                      cy="17.5"
                      r="17.5"
                      fill="#E5414E"
                    />
                    <g id="plus">
                      <g id="Group">
                        <path
                          id="Union"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M17.623 15.6641L22.4351 10.8521C22.8475 10.4396 23.3975 10.4396 23.8099 10.8521C24.2224 11.2645 24.2224 11.8145 23.8099 12.2269L18.9978 17.0391L23.8097 21.8509C24.2222 22.2634 24.2222 22.8134 23.8097 23.2258C23.3972 23.6383 22.8473 23.6383 22.4348 23.2258L17.6227 18.4137L12.8107 23.2258C12.3982 23.6383 11.8482 23.6383 11.4358 23.2258C11.0233 22.8134 11.0233 22.2634 11.4358 21.8509L16.2479 17.0388L11.436 12.2269C11.0236 11.8145 11.0236 11.2645 11.436 10.8521C11.8485 10.4396 12.3984 10.4396 12.8109 10.8521L17.623 15.6641Z"
                          fill="white"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </button>
            )
          }
          {...register("password")}
        />
        <Button
          variant="outlined"
          size="sm"
          fullWidth
          onClick={handleClickRecovery}
        >
          Забыли пароль?
        </Button>
      </Box>
      <div className={styles.auth_form__info}>
        {error && (
          <div className={styles.auth_form__info__alert}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <g id="danger">
                <g id="danger_2">
                  <path
                    d="M12 6C12.5523 6 13 6.44772 13 7V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V7C11 6.44772 11.4477 6 12 6Z"
                    fill="#D44333"
                  />
                  <path
                    d="M12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16Z"
                    fill="#D44333"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12Z"
                    fill="#D44333"
                  />
                </g>
              </g>
            </svg>
            <span className={styles.auth_form__info__alert__message}>
              Неправильно введен телефон или пароль
            </span>
          </div>
        )}
      </div>
      <Button type="submit" className={styles.auth_form__button}>
        Войти
      </Button>
    </form>
  );
};
