import {
  ChannelThreadAddApi,
  ChannelThreadCommentsAddApi,
  ChannelThreadCommentsDeleteApi,
  ChannelThreadCommentsListApi,
  ChannelThreadDeleteApi,
  ChannelThreadListApi,
  ChannelThreadRatingAddApi,
  ChannelThreadViewApi,
  Comment,
  Thread,
} from "@shared/models/channel";
import { requestInstance } from "@shared/request-instance";

export const channelThreadListApi: ChannelThreadListApi = async (channelId) => {
  const response = await requestInstance.post("/channel/thread/list", {
    channelId,
  });

  return response.data;
};

export const channelThreadCommentsListApi: ChannelThreadCommentsListApi =
  async (threadId) => {
    const response = await requestInstance.post(
      "/channel/thread/comments/list",
      { threadId }
    );

    return response.data;
  };

export const channelThreadAddApi: ChannelThreadAddApi = async (params) => {
  const response = await requestInstance.post<Thread>(
    "/channel/thread/add",
    params
  );

  return response.data;
};

export const channelThreadDeleteApi: ChannelThreadDeleteApi = async (
  params
) => {
  await requestInstance.post("/channel/thread/delete", params);
};

export const channelThreadCommentsAddApi: ChannelThreadCommentsAddApi = async (
  params
) => {
  const response = await requestInstance.post<Comment>(
    "/channel/thread/comments/add",
    params
  );

  return response.data;
};

export const channelThreadCommentsDeleteApi: ChannelThreadCommentsDeleteApi =
  async (params) => {
    await requestInstance.post("/channel/thread/comments/delete", params);
  };

export const channelThreadViewApi: ChannelThreadViewApi = async ({
  threadId,
  messageId,
}) =>
  requestInstance.get("/channel/thread/view", {
    params: { threadId, messageId },
  });

export const channelThreadRatingAddApi: ChannelThreadRatingAddApi = async (
  params
) => {
  const response = await requestInstance.post(
    "/channel/thread/rating/add",
    params
  );

  return response.data;
};
