import type { AuthRefreshResponse } from "./auth";

export type UserId = number;

export enum UserRoleTypes {
  User,
  StoreAdmin,
  Admin = 9,
  CenterOfficeAdmin = 11,
  RegionalAdmin = 12,
}

export interface User {
  fullName: string;
  login: string;
  position: string;
  phone: string;
  userRoles: UserRoleTypes[];
  role: number[];
  userId: UserId;
}

export type GetUserApi = () => Promise<AuthRefreshResponse>;
