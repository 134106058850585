import { createSelector } from "@reduxjs/toolkit";
import { ChannelId } from "@shared/models/channel";
import { Region, RegionDepartment } from "@shared/models/regions";
import {
  EditableExtendedStore,
  ExtendedStore,
  Store,
  StoreFilters,
  StoreWithStatus,
  StoreWithZones,
} from "@shared/models/stores";
import { ZoneChannel } from "@shared/models/zones";
import { getAccumulatedZoneStatus } from "@shared/utils/get-accumulated-zone-status";
import { RootSelector } from "../types";
import { activeZonesSelector } from "../zones/selector";

export const storesSelector: RootSelector<Store[]> = (state) =>
  state.stores.stores;

export const storesWithZonesSelector: RootSelector<StoreWithZones[]> = (
  state
) => state.stores.storesWithZones;

export const storeFilterSelector: RootSelector<StoreFilters | undefined> = (
  state
) => state.stores.storeFilters;

export const storesWithoutPhotoFilterSelector: RootSelector<boolean> = (
  state
) => state.stores.withoutPhoto ?? false;

export const isZonesEditModalOpenSelector: RootSelector<boolean> = (state) =>
  state.stores.isZonesEditModalOpen ?? false;

export const storeCurrentStoreId: RootSelector<string | undefined> = (state) =>
  state.stores.store?.store.storeId;

export const storeZonesSettingsSelector: RootSelector<
  EditableExtendedStore | undefined
> = (state) => state.stores.storeZonesSettings;

export const editableStoreZonesSettingsSelector = createSelector(
  storeZonesSettingsSelector,
  (storeZonesSettings) =>
    storeZonesSettings?.storeZones.map(
      ({ isEnabled, zoneId, minPhotosCount, zone }) => ({
        isEnabled,
        zoneId,
        name: zone.name,
        minPhotosCount:
          minPhotosCount != null ? minPhotosCount : zone.minPhotosCount,
        zone,
      })
    )
);

export const storesSearchSelector: RootSelector<string> = (state) =>
  state.stores.search ?? "";

export const storesWithStatusSelector = createSelector(
  storesWithZonesSelector,
  activeZonesSelector,
  (stores, activeZones) =>
    stores.map<StoreWithStatus>(({ store, zoneInfos, ratings }) => {
      const filteredZones =
        activeZones.length > 0
          ? zoneInfos.filter(({ zoneId }) => activeZones.includes(zoneId))
          : zoneInfos;
      const status = getAccumulatedZoneStatus(filteredZones);

      return { store, zoneInfos: filteredZones, status, ratings };
    })
);

export const filteredStoresWithStatusSelector = createSelector(
  storesWithStatusSelector,
  storesWithoutPhotoFilterSelector,
  storesSearchSelector,
  activeZonesSelector,
  (stores, storesWithoutPhotoFilter, storesSearch, activeZones) =>
    stores.filter(({ store: { storeId, city, name }, zoneInfos, status }) => {
      if (storesWithoutPhotoFilter && status !== 2) {
        return false;
      }

      if (
        activeZones.length > 0 &&
        !zoneInfos.some(({ zoneId }) => activeZones.includes(zoneId))
      ) {
        return false;
      }

      return (
        storeId.toLowerCase().includes(storesSearch.toLowerCase()) ||
        city.toLowerCase().includes(storesSearch.toLowerCase()) ||
        name.toLowerCase().includes(storesSearch.toLowerCase())
      );
    })
);

export const storesWithoutPhotosSelector = createSelector(
  storesWithStatusSelector,
  (stores) => stores.filter(({ status }) => status === 2).length
);

export const regionsSelector = createSelector(storesSelector, (stores) => {
  const regions = new Map<string, Region>();

  stores.forEach(({ region }) => {
    if (!regions.has(region)) {
      const departments = new Map<string, RegionDepartment>();

      const regionStores = stores.filter((store) => store.region === region);

      regionStores.forEach(({ department }) => {
        const departmentStores = regionStores.filter(
          (store) => store.department === department
        );

        if (!departments.has(department)) {
          departments.set(department, { department, stores: departmentStores });
        }
      });

      regions.set(region, {
        region,
        departments: Array.from(departments.values()),
        stores: regionStores,
      });
    }
  });

  return Array.from(regions.values());
});

export const storeSelector: RootSelector<ExtendedStore | undefined> = (state) =>
  state.stores.store;

export const storeZonesSelector = createSelector(
  storeSelector,
  activeZonesSelector,
  (store, activeZones) => {
    if (!store) {
      return [];
    }

    if (activeZones.length === 0) {
      return store.storeZones;
    }

    return store?.storeZones.filter(({ zoneId }) =>
      activeZones.includes(zoneId)
    );
  }
);

export const storeZoneChannelSelector = (channelId: ChannelId) =>
  createSelector(storeSelector, (store): ZoneChannel | undefined => {
    const storeZones = store?.storeZones ?? [];

    for (let index = 0; index < storeZones.length; index += 1) {
      const element = storeZones[index];

      if (element.channel.channelId === channelId) {
        return element.channel;
      }

      if (element.zone.channel.channelId === channelId) {
        return element.zone.channel;
      }
    }

    return undefined;
  });
