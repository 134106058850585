import { LoadingStatus } from "@shared/store/loading/types";

export type MediaKey = string;

export const enum MediaType {
  Image = 1,
  Video = 2,
  File = 3,
}

export interface MediaCommon {
  key: MediaKey;
  type: MediaType;
  link: string;
  fileName?: string;
  createdDate?: string;
  uploadDate?: string;
}

export interface MediasDownloadParams {
  fileName: string;
  url: string;
}

export interface MediaImage extends MediaCommon {
  type: MediaType.Image;
}

export interface MediaVideo extends MediaCommon {
  type: MediaType.Video;
  previewLink: string;
}

export interface MediaFile extends MediaCommon {
  type: MediaType.File;
}

export type Media = MediaImage | MediaVideo | MediaFile;

export interface MediaWithLoadingStatus {
  id: string;
  media: Media;
  loadingStatus: LoadingStatus;
  title?: string;
}

export type MediaUploadApi = (file: File) => Promise<Media>;

export type MediasUploadApi = (file: FileList) => Promise<Media[]>;

export type MediasDownloadApi = (params: MediasDownloadParams) => Promise<void>;
