import { FC, useState } from "react";
import { TextField } from "@shared/ui/text-field";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Box, Button, Option, Select } from "@mui/joy";
import ReactInputMask from "react-input-mask";
import {
  CountryCodes,
  CountryMasks,
  CountryNames,
  CountryTypes,
  countryTypes,
} from "@shared/store/auth/types";
import { AuthPasswordResetRequest } from "@shared/models/auth";
import styles from "./index.module.scss";

interface RecoveryFormProps {
  error?: string;
  onSubmit: SubmitHandler<AuthPasswordResetRequest>;
}

export const RecoveryForm: FC<RecoveryFormProps> = ({ error, onSubmit }) => {
  const [openListBox, setOpenListBox] = useState(false);

  const {
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm<AuthPasswordResetRequest>({
    defaultValues: { phone: "", country: CountryTypes.Ru },
    resolver: (values) => {
      const { phone } = values;

      const phoneError =
        phone === "" || phone.includes("*") ? { type: "validate" } : undefined;

      if (phoneError) {
        return {
          values,
          errors: {
            phone: phoneError,
          },
        };
      }

      return { values, errors: {} };
    },
  });

  const country = watch("country");

  return (
    <form className={styles.auth_form} onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ display: "flex", height: 50, gap: 1 }}>
        <Select
          slotProps={{
            listbox: { placement: "bottom-start" },
          }}
          listboxOpen={openListBox}
          // eslint-disable-next-line react/jsx-handler-names
          onListboxOpenChange={setOpenListBox}
          value={country}
          onChange={(_, value) => {
            if (value) {
              setValue("phone", "");
              setValue("country", value);
            }
          }}
          renderValue={(option) =>
            option && <strong>{CountryCodes[option.value]}</strong>
          }
          indicator={
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ transform: openListBox ? "rotate(90deg)" : undefined }}
            >
              <path
                d="M9 18.5L15 12.5L9 6.5"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          }
        >
          {countryTypes.map((country) => (
            <Option key={country} value={country}>
              <Box component="strong" width={60}>
                {CountryCodes[country]}
              </Box>
              <span>{CountryNames[country]}</span>
            </Option>
          ))}
        </Select>
        <Controller
          control={control}
          name="phone"
          rules={{ required: { message: "", value: true } }}
          render={({ field: { value, onChange: handleChange } }) => (
            <ReactInputMask
              mask={CountryMasks[country]}
              maskChar="*"
              alwaysShowMask
              value={value}
              onChange={handleChange}
            >
              {
                // @ts-expect-error -- Ошибка типов
                (inputProps) => (
                  <TextField
                    sx={{ flexGrow: 1, height: "inherit" }}
                    inputSx={{ height: "inherit" }}
                    {...inputProps}
                    type="tel"
                    error={Boolean(errors.phone)}
                  />
                )
              }
            </ReactInputMask>
          )}
        />
      </Box>
      <div className={styles.auth_form__info}>
        {error && (
          <div className={styles.auth_form__info__alert}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <g id="danger">
                <g id="danger_2">
                  <path
                    d="M12 6C12.5523 6 13 6.44772 13 7V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V7C11 6.44772 11.4477 6 12 6Z"
                    fill="#D44333"
                  />
                  <path
                    d="M12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16Z"
                    fill="#D44333"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12Z"
                    fill="#D44333"
                  />
                </g>
              </g>
            </svg>
            <span className={styles.auth_form__info__alert__message}>
              {error}
            </span>
          </div>
        )}
      </div>
      <Button type="submit" className={styles.auth_form__button}>
        Отправить код
      </Button>
    </form>
  );
};
