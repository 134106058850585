import { createBrowserRouter } from "react-router-dom";
import { AppLayout } from "@app/_layout";
import { dynamic } from "@shared/lib/dynamic";

const ZonesLayout = dynamic(() => import("./pages/zones/_layout"));
const AddZonePage = dynamic(() => import("./pages/zones/add-zone"));
const NotFoundPage = dynamic(() => import("./pages/not-found"));

const StoreStoreIdReferenceChannelChannelIdLayout = dynamic(
  () => import("./pages/store/store-id/reference-channel/channel-id/_layout")
);
const StoreStoreIdReferenceChannelChannelIdPage = dynamic(
  () => import("./pages/store/store-id/reference-channel/channel-id")
);
const StoreStoreIdReferenceChannelChannelIdThreadIdPage = dynamic(
  () => import("./pages/store/store-id/reference-channel/channel-id/thread-id")
);
const StoreStoreIdChannelChannelIdPage = dynamic(
  () => import("./pages/store/store-id/channel/channel-id")
);
const StoreStoreIdChannelChannelIdThreadIdPage = dynamic(
  () => import("./pages/store/store-id/channel/channel-id/thread-id")
);
const StoreStoreIdChannelChannelIdLayout = dynamic(
  () => import("./pages/store/store-id/channel/channel-id/_layout")
);

const IndexPage = dynamic(() => import("@app/pages/index"));
const ZonesPage = dynamic(() => import("@app/pages/zones/index"));
const StoreStoreIdLayout = dynamic(
  () => import("@app/pages/store/store-id/_layout")
);

export const router = createBrowserRouter([
  {
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: <IndexPage />,
      },
      {
        path: "zones",
        element: <ZonesLayout />,
        children: [
          { index: true, element: <ZonesPage /> },
          { path: "add", element: <AddZonePage /> },
        ],
      },
      {
        path: "store",
        element: <StoreStoreIdLayout />,
        children: [
          {
            path: ":storeId",
            children: [
              {
                path: "reference-channel",
                children: [
                  {
                    path: ":channelId",
                    element: <StoreStoreIdReferenceChannelChannelIdLayout />,
                    children: [
                      {
                        index: true,
                        element: <StoreStoreIdReferenceChannelChannelIdPage />,
                      },
                      {
                        path: ":threadId",
                        element: (
                          <StoreStoreIdReferenceChannelChannelIdThreadIdPage />
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                path: "channel",
                children: [
                  {
                    path: ":channelId",
                    element: <StoreStoreIdChannelChannelIdLayout />,
                    children: [
                      {
                        index: true,
                        element: <StoreStoreIdChannelChannelIdPage />,
                      },
                      {
                        path: ":threadId",
                        element: <StoreStoreIdChannelChannelIdThreadIdPage />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
    ],
  },
]);
