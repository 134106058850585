import {
  storeListApi,
  storeListWithZonesApi,
  storeZoneListApi,
  storeZoneSettingsApi,
  updateStoreZonesSettingsApi,
} from "@shared/api/stores";
import { createAppAsyncThunk } from "../create-app-async-thunk";
import { storesDomain } from "./constants";

export const storeList = createAppAsyncThunk(
  `${storesDomain}/storeList`,
  storeListApi
);

export const storeListWithZones = createAppAsyncThunk(
  `${storesDomain}/storeListWithZones`,
  storeListWithZonesApi
);

export const storeZoneList = createAppAsyncThunk(
  `${storesDomain}/storeZoneList`,
  storeZoneListApi
);

export const storeZoneSettings = createAppAsyncThunk(
  `${storesDomain}/storeZoneSettings`,
  storeZoneSettingsApi
);

export const updateStoreZonesSettings = createAppAsyncThunk(
  `${storesDomain}/updateStoreZonesSettings`,
  updateStoreZonesSettingsApi
);
