import { FC, useCallback, useState } from "react";
import { AuthForm } from "@shared/components/auth-form";

import { AuthCard } from "@shared/components/auth-card";
import { useAppDispatch, useAppSelector } from "@shared/store/hooks";
import {
  authLogin,
  authPasswordChange,
  authPasswordReset,
  authPasswordResetCheck,
} from "@shared/store/auth/async-thunks";
import { SubmitHandler } from "react-hook-form";
import {
  AuthLoginForm,
  AuthPasswordFormData,
  AuthPasswordResetRequest,
  CodeFormData,
} from "@shared/models/auth";
import {
  authRecoverySelector,
  loadingSelector,
} from "@shared/store/loading/selectors";
import { PasswordForm } from "@shared/components/password-form";
import { Box, IconButton } from "@mui/joy";
import { CodeForm } from "@shared/components/code-form";
import { RecoveryForm } from "@shared/components/recovery-form";
import { Button } from "@shared/ui/button";
import { loadingSlice } from "@shared/store/loading";
import styles from "./index.module.scss";

type AuthStage = "auth" | "recovery" | "code" | "password" | "passwordSuccess";

export const AuthContainer: FC = () => {
  const [authStage, setAuthStage] = useState<AuthStage>("auth");

  const dispatch = useAppDispatch();

  const { loadingStatus } = useAppSelector(
    loadingSelector(authLogin.typePrefix)
  );

  const { error } = useAppSelector(
    loadingSelector(authPasswordReset.typePrefix)
  );

  const { error: checkError } = useAppSelector(
    loadingSelector(authPasswordResetCheck.typePrefix)
  );

  const { error: changeError } = useAppSelector(
    loadingSelector(authPasswordChange.typePrefix)
  );

  const { token, phone, country, expired } =
    useAppSelector(authRecoverySelector);

  const handleLogin = useCallback<SubmitHandler<AuthLoginForm>>(
    (params) => {
      dispatch(authLogin(params));
    },
    [dispatch]
  );

  const handleRecovery = useCallback<SubmitHandler<AuthPasswordResetRequest>>(
    (params) => {
      dispatch(authPasswordReset(params))
        .unwrap()
        .then(() => {
          setAuthStage("code");
        })
        .catch(() => {
          setAuthStage("recovery");
        });
    },
    [dispatch]
  );

  const handleCode = useCallback<SubmitHandler<CodeFormData>>(
    ({ code }) => {
      if (token) {
        dispatch(authPasswordResetCheck({ code, token })).then(() => {
          setAuthStage("password");
        });
      }
    },
    [dispatch, token]
  );

  const handleResubmit = useCallback(() => {
    if (phone && country) {
      dispatch(authPasswordReset({ phone, country })).then(() => {
        setAuthStage("code");
      });
    }
  }, [country, dispatch, phone]);

  const handlePassword = useCallback<SubmitHandler<AuthPasswordFormData>>(
    ({ password }) => {
      if (token) {
        dispatch(authPasswordChange({ token, password })).then(() => {
          setAuthStage("passwordSuccess");
          dispatch(loadingSlice.actions.reset(authLogin.typePrefix));
          dispatch(loadingSlice.actions.reset(authPasswordReset.typePrefix));
          dispatch(
            loadingSlice.actions.reset(authPasswordResetCheck.typePrefix)
          );
          dispatch(loadingSlice.actions.reset(authPasswordChange.typePrefix));
        });
      }
    },
    [dispatch, token]
  );

  const handleClickRecovery = () => {
    setAuthStage("recovery");
  };

  return (
    <div className={styles.auth_container}>
      <AuthCard
        title={
          <>
            {authStage === "auth" && "Авторизация"}
            {authStage === "recovery" && (
              <>
                <IconButton
                  size="sm"
                  variant="plain"
                  onClick={() => {
                    setAuthStage("auth");
                  }}
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M20.8078 6.72455C21.1202 7.03697 21.1202 7.54351 20.8078 7.85592L12.6777 15.9859L20.8078 24.116C21.1202 24.4284 21.1202 24.9349 20.8078 25.2473C20.4954 25.5598 19.9888 25.5598 19.6764 25.2473L10.9807 16.5516C10.8306 16.4016 10.7463 16.1981 10.7463 15.9859C10.7463 15.7737 10.8306 15.5702 10.9807 15.4202L19.6764 6.72455C19.9888 6.41213 20.4954 6.41213 20.8078 6.72455Z"
                      fill="#262626"
                    />
                  </svg>
                </IconButton>
                <span style={{ flexGrow: 1 }}>Восстановление пароля</span>
              </>
            )}
            {authStage === "code" && (
              <>
                <IconButton
                  size="sm"
                  variant="plain"
                  onClick={() => {
                    setAuthStage("recovery");
                  }}
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M20.8078 6.72455C21.1202 7.03697 21.1202 7.54351 20.8078 7.85592L12.6777 15.9859L20.8078 24.116C21.1202 24.4284 21.1202 24.9349 20.8078 25.2473C20.4954 25.5598 19.9888 25.5598 19.6764 25.2473L10.9807 16.5516C10.8306 16.4016 10.7463 16.1981 10.7463 15.9859C10.7463 15.7737 10.8306 15.5702 10.9807 15.4202L19.6764 6.72455C19.9888 6.41213 20.4954 6.41213 20.8078 6.72455Z"
                      fill="#262626"
                    />
                  </svg>
                </IconButton>
                <span style={{ flexGrow: 1 }}>Восстановление пароля</span>
              </>
            )}
            {authStage === "password" && (
              <>
                <IconButton
                  size="sm"
                  variant="plain"
                  onClick={() => {
                    setAuthStage("auth");
                  }}
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M20.8078 6.72455C21.1202 7.03697 21.1202 7.54351 20.8078 7.85592L12.6777 15.9859L20.8078 24.116C21.1202 24.4284 21.1202 24.9349 20.8078 25.2473C20.4954 25.5598 19.9888 25.5598 19.6764 25.2473L10.9807 16.5516C10.8306 16.4016 10.7463 16.1981 10.7463 15.9859C10.7463 15.7737 10.8306 15.5702 10.9807 15.4202L19.6764 6.72455C19.9888 6.41213 20.4954 6.41213 20.8078 6.72455Z"
                      fill="#262626"
                    />
                  </svg>
                </IconButton>
                <span style={{ flexGrow: 1 }}>Восстановление пароля</span>
              </>
            )}
            {authStage === "passwordSuccess" && "Пароль успешно изменен"}
          </>
        }
      >
        {authStage === "auth" && (
          <AuthForm
            error={loadingStatus === "rejected"}
            onSubmit={handleLogin}
            onClickRecovery={handleClickRecovery}
          />
        )}
        {authStage === "recovery" && (
          <RecoveryForm error={error} onSubmit={handleRecovery} />
        )}
        {authStage === "code" && (
          <CodeForm
            phone={phone}
            error={checkError}
            country={country}
            expired={expired}
            onSubmit={handleCode}
            onResubmit={handleResubmit}
          />
        )}
        {authStage === "password" && (
          <PasswordForm error={changeError} onSubmit={handlePassword} />
        )}
        {authStage === "passwordSuccess" && (
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="solid"
              size="lg"
              sx={{ minHeight: 50 }}
              fullWidth
              onClick={() => {
                setAuthStage("auth");
              }}
            >
              Авторизоваться
            </Button>
          </Box>
        )}
      </AuthCard>
    </div>
  );
};
