import {
  authLoginApi,
  authLogoutApi,
  authPasswordResetApi,
  authPasswordResetChangeApi,
  authPasswordResetCheckApi,
  authRefreshApi,
} from "@shared/api/auth";
import { createAppAsyncThunk } from "../create-app-async-thunk";
import { authDomain } from "./constants";

export const authLogin = createAppAsyncThunk(
  `${authDomain}/authLogin`,
  authLoginApi
);

export const authRefresh = createAppAsyncThunk(
  `${authDomain}/authRefresh`,
  authRefreshApi
);

export const authLogout = createAppAsyncThunk(
  `${authDomain}/authLogout`,
  authLogoutApi
);

export const authPasswordReset = createAppAsyncThunk(
  `${authDomain}/authPasswordReset`,
  authPasswordResetApi
);

export const authPasswordResetCheck = createAppAsyncThunk(
  `${authDomain}/authPasswordResetCheck`,
  authPasswordResetCheckApi
);

export const authPasswordChange = createAppAsyncThunk(
  `${authDomain}/authPasswordChange`,
  authPasswordResetChangeApi
);
