import { App } from "@app/index";
import { appRootId } from "@shared/constants";
import { createRoot } from "react-dom/client";

import "@shared/store/stores/middleware";
import "@shared/store/zones/middleware";
import "@shared/store/channel/middleware";

const root = createRoot(
  document.querySelector(`#${appRootId}`) ?? document.body
);

root.render(<App />);

if (module.hot) {
  module.hot.accept();
}
