import { FC, ReactNode } from "react";

import styles from "./index.module.scss";

interface AuthCardProps {
  title?: ReactNode;
  children?: ReactNode;
}

export const AuthCard: FC<AuthCardProps> = ({ title, children }) => (
  <div className={styles.auth_card}>
    <h1 className={styles.auth_card__title}>{title}</h1>
    {children}
  </div>
);
