import axios from "axios";

import { API_HOST } from "./constants";

export const authRequestInstance = axios.create({
  baseURL: `https://${API_HOST}/api`,
});

export const requestInstance = axios.create({
  baseURL: `https://${API_HOST}/api`,
});
