import { startAppListening } from "../listener-middleware";
import { storeZoneList } from "../stores/async-thunks";
import { storeCurrentStoreId } from "../stores/selectors";
import { channelThreadRatingAdd } from "./async-thunks";

startAppListening({
  actionCreator: channelThreadRatingAdd.fulfilled,
  effect: (_, { dispatch, getState }) => {
    const storeId = storeCurrentStoreId(getState());

    if (storeId != null) {
      dispatch(storeZoneList(storeId));
    }
  },
});
