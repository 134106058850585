import { createSelector } from "@reduxjs/toolkit";
import { DisplayZone, Zone, ZoneId } from "@shared/models/zones";
import { RootSelector } from "../types";

export const zonesSelector: RootSelector<Zone[]> = (state) => state.zones.zones;

export const activeZonesSelector: RootSelector<ZoneId[]> = (state) =>
  state.zones.activeZoneIds;

export const managedZoneSelector: RootSelector<Zone | undefined> = (state) =>
  state.zones.managedZone;

export const isDeleteZoneModalOpenSelector: RootSelector<boolean> = (state) =>
  state.zones.isDeleteZoneModalOpen;

export const displayZonesSelector = createSelector(
  zonesSelector,
  activeZonesSelector,
  (zones, activeZones) =>
    zones.map<DisplayZone>((zone) => ({
      zone,
      active: activeZones.includes(zone.id),
    }))
);
