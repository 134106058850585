import { requestInstance, authRequestInstance } from "@shared/request-instance";
import {
  AuthLoginApi,
  AuthLoginResponse,
  AuthLogoutApi,
  AuthPasswordChangeApi,
  AuthPasswordResetApi,
  AuthPasswordResetCheckApi,
  AuthPasswordResetResponse,
  AuthPasswordResponse,
  AuthRefreshApi,
} from "@shared/models/auth";
import Cookies from "js-cookie";
import { getNormalizedPhone } from "@shared/utils/phone";
import { CountryCodes } from "@shared/store/auth/types";
import axios from "axios";
import { isPlainObject } from "@shared/lib/type-guards/is-plain-object";
import { getUserApi } from "./user";

const tokenKey = "token";
const refreshTokenKey = "refreshToken";
const xTenantKey = "X-TENANT-KEY";

export const authLoginApi: AuthLoginApi = async ({
  country,
  phone,
  password,
}) => {
  if (phone === "") {
    throw new Error("Введите логин");
  }

  if (password === "") {
    throw new Error("Введите пароль");
  }

  const response = await authRequestInstance.post<AuthLoginResponse>(
    "/auth/v2/login",
    {
      phone: getNormalizedPhone(`${CountryCodes[country]}${phone}`),
      password,
    }
  );

  Cookies.set(tokenKey, response.data.token);
  Cookies.set(refreshTokenKey, response.data.refreshToken);

  requestInstance.defaults.headers.Authorization = `Bearer ${response.data.token}`;

  const xTenantKey = response.data.tenantEmployeeInfos?.[0]?.tenant.key;

  if (xTenantKey) {
    requestInstance.defaults.headers.xTenantKey = xTenantKey;
  }

  return response.data;
};

export const authRefreshApi: AuthRefreshApi = async () => {
  const token = Cookies.get(tokenKey);
  const refreshToken = Cookies.get(refreshTokenKey);

  if (token == null || refreshToken == null) {
    throw new Error("Нет данных пользователя");
  }

  const response = await authRequestInstance.put<AuthLoginResponse>(
    "/auth/v2/refresh",
    {
      token,
      refreshToken,
    }
  );

  Cookies.set(tokenKey, response.data.token);
  Cookies.set(refreshTokenKey, response.data.refreshToken);

  requestInstance.defaults.headers.Authorization = `Bearer ${response.data.token}`;

  const data = await getUserApi();

  const xTenantKey = data.tenants?.[0].tenant.key;

  if (xTenantKey) {
    requestInstance.defaults.headers.xTenantKey = xTenantKey;
  }

  return {
    ...response.data,
    user: data.user,
    tenantEmployeeInfos: data.tenants,
  };
};

export const authLogoutApi: AuthLogoutApi = async () => {
  await requestInstance.delete("/auth/v2/logout");

  Cookies.remove(tokenKey);
  Cookies.remove(refreshTokenKey);

  delete requestInstance.defaults.headers.Authorization;
  delete requestInstance.defaults.headers[xTenantKey];
};

export const authPasswordResetApi: AuthPasswordResetApi = async ({
  phone,
  country,
}) => {
  try {
    const response = await authRequestInstance.post<AuthPasswordResetResponse>(
      "/auth/v2/password/reset",
      {
        userIdentifier: getNormalizedPhone(`${CountryCodes[country]}${phone}`),
        providerType: 0,
      }
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      if (
        error.response.status === 400 &&
        isPlainObject(error.response.data) &&
        typeof error.response.data.message === "string"
      ) {
        throw new Error(
          `Код проверки был запрошен ранее, повторите после ${error.response.data.message.replace(
            "Old code not expired = ",
            ""
          )}`
        );
      }

      if (error.response.status === 429) {
        throw new Error("Повторите запрос кода через 1 минуту");
      }
    }

    throw new Error("Произошла ошибка");
  }
};

export const authPasswordResetCheckApi: AuthPasswordResetCheckApi = async (
  params
) => {
  try {
    const response = await authRequestInstance.post<AuthPasswordResponse>(
      "/auth/v2/password/reset/check",
      params
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      if (error.response.status === 429) {
        throw new Error(
          "Превышено количество попыток ввода, повторите через 1 минуту"
        );
      }
      if (isPlainObject(error.response.data)) {
        if (error.response.data.type === 1) {
          throw new Error("Введённый код просрочен, повторите отправку");
        }
        if (error.response.data.type === 2) {
          throw new Error("Введенный код некорректный, повторите ввод");
        }
      }
    }

    throw new Error("Неверный код");
  }
};

export const authPasswordResetChangeApi: AuthPasswordChangeApi = async (
  params
) => {
  try {
    const response = await authRequestInstance.put<AuthPasswordResponse>(
      "/auth/v2/password/change",
      params
    );

    return response.data;
  } catch {
    throw new Error("Ошибка изменения пароля");
  }
};
