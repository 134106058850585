import { AuthState } from "../auth/types";
import { RootSelector, RootState } from "../types";
import { loadingEntityAdapter } from "./entity-adapters";
import { LoadingItem } from "./types";

export const loadingEntityAdapterSelectors =
  loadingEntityAdapter.getSelectors<RootState>((state) => state.loading);

export const loadingSelector =
  (typePrefix: string): RootSelector<LoadingItem> =>
  (state) =>
    loadingEntityAdapterSelectors.selectById(state, typePrefix) ?? {
      typePrefix,
      loadingStatus: "initial",
    };

export const authRecoverySelector: RootSelector<AuthState["recovery"]> = (
  state
) => state.auth.recovery;
