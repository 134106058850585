import { FC, ReactNode, lazy, Suspense } from "react";

type DynamicImport = () => Promise<{ default: FC }>;

interface DynamicOptions {
  fallback?: ReactNode;
}

type Dynamic = (
  dynamicImport: DynamicImport,
  dynamicOptions?: DynamicOptions
) => FC;

export const dynamic: Dynamic = (dynamicImport, dynamicOptions) => {
  const LazyComponent = lazy(dynamicImport);

  const SuspenseComponent = () => (
    <Suspense fallback={dynamicOptions?.fallback}>
      <LazyComponent />
    </Suspense>
  );

  return SuspenseComponent;
};
