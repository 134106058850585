import { useState, useCallback, useRef, useEffect } from "react";

export interface CountdownParams {
  timer: number;
  interval?: number;
  autostart?: boolean;
  expireImmediate?: boolean;
  onExpire?: () => void;
  onReset?: () => void;
}

export interface CountdownResults {
  countdown: number;
  countdownInSeconds: number;
  isCountdownRunning: boolean;
  startCountdown: () => void;
  resetCountdown: () => void;
  pauseCountdown: () => void;
  setupCountdown: (time: number) => void;
}

export const useCountdown = ({
  timer,
  interval = 1000,
  autostart = false,
  expireImmediate = false,
  onExpire,
  onReset,
}: CountdownParams): CountdownResults => {
  const [countdown, setCountdown] = useState(timer);
  const [countdownInSeconds, setCountdownInSeconds] = useState(timer / 1000);
  const [canStart, setCanStart] = useState(autostart);
  const [isRunning, setIsRunning] = useState(false);

  const start = useCallback(() => {
    setCanStart(true);
  }, []);

  const pause = () => {
    setCanStart(false);
    setIsRunning(false);
  };

  const initStopped = (time: number) => {
    setCanStart(false);
    setIsRunning(false);
    setCountdown(time);
  };

  const reset = useCallback(() => {
    initStopped(timer);

    if (onReset && typeof onReset === "function") {
      onReset();
    }
  }, [timer, onReset]);

  const expire = useCallback(() => {
    initStopped(timer);

    if (onExpire && typeof onExpire === "function") {
      onExpire();
    }
  }, [timer, onExpire]);

  const setup = useCallback(
    (time: number) => {
      setCountdown(time);
    },
    [setCountdown]
  );

  const countdownRef = useRef<number>(timer);

  useEffect(() => {
    countdownRef.current = countdown;
  }, [countdown]);

  useEffect(() => {
    const tick = () => {
      const isExpire =
        countdownRef.current / 1000 <= 0 ||
        (expireImmediate && (countdownRef.current - interval) / 1000 <= 0);

      if (isExpire) {
        expire();
      } else {
        setCountdown((previous) => previous - interval);
        setCountdownInSeconds((previous) => previous - interval / 1000);
      }
    };

    let id: NodeJS.Timer | null = null;

    if (canStart) {
      id = setInterval(tick, interval);

      if (!isRunning) {
        setIsRunning(true);
      }
    }

    return () => {
      if (id) {
        clearInterval(id);
      }
    };
  }, [countdown, expire, canStart, interval, expireImmediate, isRunning]);

  return {
    countdown,
    countdownInSeconds,
    isCountdownRunning: isRunning,
    startCountdown: start,
    resetCountdown: reset,
    pauseCountdown: pause,
    setupCountdown: setup,
  };
};
