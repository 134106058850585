import { CircularProgress } from "@mui/joy";
import { LoadingStatus } from "@shared/store/loading/types";
import { FC, ReactNode } from "react";

import styles from "./index.module.scss";

interface PageContentProps {
  children?: ReactNode;
  error?: ReactNode;
  loader?: ReactNode;
  loadingStatus?: LoadingStatus;
  emptyInitial?: boolean;
  renderOnFail?: boolean;
}

const PageContent: FC<PageContentProps> = ({
  loadingStatus,
  children,
  loader = <CircularProgress />,
  error = "Ошибка загрузки данных",
  emptyInitial = false,
  renderOnFail = false,
}) => {
  if (emptyInitial && loadingStatus === "initial") {
    return null;
  }

  if (loadingStatus === "fulfilled" || renderOnFail) {
    return <>{children}</>;
  }

  if (loadingStatus === "rejected") {
    return <div className={styles.root}>{error}</div>;
  }

  return <div className={styles.root}>{loader}</div>;
};

export default PageContent;
