import { configureStore } from "@reduxjs/toolkit";
import { requestInstance } from "@shared/request-instance";
import axios from "axios";
import { authRefresh } from "./auth/async-thunks";
import { listenerMiddleware } from "./listener-middleware";
import { authSlice } from "./auth";
import { loadingSlice } from "./loading";
import { storesSlice } from "./stores";
import { zonesSlice } from "./zones";
import { channelSlice } from "./channel";

export const createAsyncStore = () => {
  const store = configureStore({
    reducer: {
      [authSlice.name]: authSlice.reducer,
      [channelSlice.name]: channelSlice.reducer,
      [loadingSlice.name]: loadingSlice.reducer,
      [storesSlice.name]: storesSlice.reducer,
      [zonesSlice.name]: zonesSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().prepend(listenerMiddleware.middleware),
  });

  requestInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (
        axios.isAxiosError(error) &&
        (error.response?.status === 401 || error.response?.status === 403)
      ) {
        const response = await store.dispatch(authRefresh());

        if (response.type === authRefresh.fulfilled.type) {
          window.location.reload();
        }
      }

      return Promise.reject(error);
    }
  );

  return { store };
};
