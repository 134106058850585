import { FC, useEffect, useState } from "react";
import { TextField } from "@shared/ui/text-field";
import { SubmitHandler } from "react-hook-form";
import { Box, Button } from "@mui/joy";
import { CountryCodes, CountryTypes } from "@shared/store/auth/types";
import { CodeFormData } from "@shared/models/auth";
import { getNormalizedPhone } from "@shared/utils/phone";
import { useCountdown } from "@shared/utils/use-countdown";
import styles from "./index.module.scss";

interface CodeFormProps {
  error?: string;
  country?: CountryTypes;
  phone?: string;
  expired?: string;
  onSubmit: SubmitHandler<CodeFormData>;
  onResubmit: () => void;
}

export const CodeForm: FC<CodeFormProps> = ({
  phone,
  error,
  expired = new Date().toString(),
  country = CountryTypes.Ru,
  onSubmit,
  onResubmit: handleResubmit,
}) => {
  const timer = +new Date(expired) - +new Date();

  const { countdownInSeconds } = useCountdown({
    timer: timer - (timer % 1000),
    autostart: true,
  });

  const [code, setCode] = useState("");

  useEffect(() => {
    if (code.length === 4) {
      onSubmit({ code });
    }
  }, [code, onSubmit]);

  const floorCountdown = Math.floor(countdownInSeconds);
  const minutes = Math.floor(floorCountdown / 60);
  const seconds = floorCountdown - minutes * 60;

  return (
    <form className={styles.auth_form}>
      <Box textAlign="center" px={3}>
        Код проверки отправлен на телефон {CountryCodes[country]}
        <span>{phone}</span>
      </Box>
      <TextField
        label="Код из смс"
        inputMode="numeric"
        type="number"
        value={code}
        onChange={(event) => {
          setCode(getNormalizedPhone(event.target.value).slice(0, 4));
        }}
      />
      {countdownInSeconds > 0 ? (
        <Box textAlign="center" px={3}>
          Повторить отправку кода можно через {minutes}:
          {seconds < 10 ? `0${seconds}` : seconds} сек
        </Box>
      ) : (
        <Button
          type="button"
          onClick={() => {
            setCode("");
            handleResubmit();
          }}
        >
          Повторить отправку кода
        </Button>
      )}
      <div className={styles.auth_form__info}>
        {error && (
          <div className={styles.auth_form__info__alert}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <g id="danger">
                <g id="danger_2">
                  <path
                    d="M12 6C12.5523 6 13 6.44772 13 7V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V7C11 6.44772 11.4477 6 12 6Z"
                    fill="#D44333"
                  />
                  <path
                    d="M12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16Z"
                    fill="#D44333"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12Z"
                    fill="#D44333"
                  />
                </g>
              </g>
            </svg>
            <span className={styles.auth_form__info__alert__message}>
              {error}
            </span>
          </div>
        )}
      </div>
    </form>
  );
};
