import { StoresState } from "./types";

export const storesDomain = "stores";

export const storeFiltersKey = "ma-store-filters";

export const storesInitialState: StoresState = {
  stores: [],
  storesWithZones: [],
};
