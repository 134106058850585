import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Zone, ZoneId } from "@shared/models/zones";
import { symmetricDifference } from "@shared/utils/symmetric-difference";
import { storesSlice } from "../stores";
import { addZone, deleteZone, editZone, zoneAll } from "./async-thunks";
import { zonesDomain, zonesInitialState } from "./constants";

export const zonesSlice = createSlice({
  name: zonesDomain,
  initialState: zonesInitialState,
  reducers: {
    toggleZone: (draft, action: PayloadAction<ZoneId>) => {
      draft.activeZoneIds = symmetricDifference(draft.activeZoneIds, [
        action.payload,
      ]);
    },
    resetActiveZones: (draft) => {
      draft.activeZoneIds = [];
    },
    managedZone: (draft, action: PayloadAction<Zone>) => {
      draft.managedZone = action.payload;
    },
    openDeleteZoneModal: (draft) => {
      draft.isDeleteZoneModalOpen = true;
    },
    closeDeleteZoneModal: (draft) => {
      draft.isDeleteZoneModalOpen = false;
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(storesSlice.actions.setStoreFilter, (draft) => {
      draft.activeZoneIds = [];
    });

    addCase(zoneAll.fulfilled, (draft, action) => {
      draft.zones = action.payload;
    });

    addCase(deleteZone.fulfilled, (draft, action) => {
      draft.isDeleteZoneModalOpen = false;
      draft.zones = draft.zones.filter(({ id }) => id !== action.meta.arg);
      draft.managedZone = draft.zones.at(0);
    });

    addCase(editZone.fulfilled, (draft, action) => {
      draft.zones = draft.zones.map((zone) => {
        if (zone.id === action.payload.id) {
          return action.payload;
        }

        return zone;
      });

      draft.managedZone = action.payload;
    });

    addCase(addZone.fulfilled, (draft, action) => {
      draft.zones.push(action.payload);
      draft.managedZone = action.payload;
    });
  },
});
