import { mediaEntityAdapter } from "./entity-adapters";
import { ChannelState } from "./types";

export const channelDomain = "channel" as const;

export const channelInitialState: ChannelState = {
  threads: [],
  comments: [],
  media: mediaEntityAdapter.getInitialState(),
};
