export const getVideoDurationInSeconds = (file: File) =>
  new Promise<number>((resolve, reject) => {
    const video = document.createElement("video");

    video.preload = "metadata";

    video.onloadedmetadata = () => {
      resolve(video.duration);
    };

    video.onerror = () => {
      reject();
    };

    video.src = window.URL.createObjectURL(file);
  });
