import { requestInstance } from "@shared/request-instance";
import {
  StoreListApi,
  StoreListWithZonesApi,
  StoreZoneListApi,
  StoreZoneSettingsApi,
  UpdateStoreZonesSettingsApi,
} from "@shared/models/stores";
import { storeFiltersKey } from "@shared/store/stores/constants";

export const storeListApi: StoreListApi = async () => {
  const response = await requestInstance.get("/store/list");

  return response.data;
};

export const storeListWithZonesApi: StoreListWithZonesApi = async (
  storeFilters
) => {
  const response = await requestInstance.post("/storeList", {
    storeFilters,
  });

  localStorage.setItem(storeFiltersKey, JSON.stringify(storeFilters));

  return response.data;
};

export const storeZoneListApi: StoreZoneListApi = async (storeId) => {
  const response = await requestInstance.get(`/storeZone/${storeId}/list`);

  return response.data;
};

export const storeZoneSettingsApi: StoreZoneSettingsApi = async (storeId) => {
  const response = await requestInstance.get(`/storeZone/${storeId}/settings`);

  return response.data;
};

export const updateStoreZonesSettingsApi: UpdateStoreZonesSettingsApi = async ({
  storeId,
  storeZones,
}) => {
  await requestInstance.put(`/storeZone/${storeId}/settings`, {
    storeZones,
  });

  return storeZoneListApi(storeId);
};
