export const symmetricDifference = <TArray>(
  firstArray: TArray[],
  secondArray: TArray[]
): TArray[] => {
  const firstArrayFiltered = firstArray.filter(
    (value) => !secondArray.includes(value)
  );
  const secondArrayFiltered = secondArray.filter(
    (value) => !firstArray.includes(value)
  );

  return firstArrayFiltered.concat(secondArrayFiltered);
};
